import React, { useState } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";

import styles from "../../../styles";
import { solutions } from "../../../constants";
import SolutionCard from "../../utils/SolutionCard";
import CalendaryPopup from "../../utils/CalendaryPopup";

const SolutionCardLarge = ({ solutionImgLg, solutionTitle, solutionContent, index, onLearnMoreClick }) => {
  return (
    <div className={`flex flex-col gap-[42px] xxl:h-[629px]`}>
      <img src={solutionImgLg} alt="solutionImg" className="h-[266px] max-w-[452.477px]" />
      <div className="flex flex-col xxl:h-[629px]">
        <h1 className={`lg:text-[24px] mb-2 font-semibold text-[22px]`}>{solutionTitle}</h1>
        <p className="leading-[140%] opacity-60 md:text-[18px] text-[16px] max-w-[439.352px] mb-[21px] xxl:flex-1">{solutionContent}</p>
        <button className="w-fit flex flex-row align-center gap-3 text-[18px] text-[#F26800] leading-[140%]" onClick={onLearnMoreClick}>
          <ArrowForwardIcon className="w-[10px] h-[10px]" />
          Learn More
        </button>
      </div>
    </div>
  );
};

const SolutionSection = ({ setActive }) => {
  const [openCalendly, setOpenCalendly] = useState(false);

  const openCalendlyPopup = () => {
    setOpenCalendly(true);
  };

  return (
    <div data-section id="solution" className={` flex flex-col items-start lg:py-[100px] max-md:pb-[60px]`}>
      <h1 className={`${styles.headline}`}>The Solution</h1>
      <div className="flex flex-col gap-[62px] xxl:hidden">
        {solutions.map((item, index) => (
          <SolutionCard key={item.id} {...item} index={index} onLearnMoreClick={openCalendlyPopup} />
        ))}
      </div>
      <div className="hidden xxl:flex xxl:justify-between w-full">
        {solutions.map((item, index) => (
          <SolutionCardLarge key={item.id} {...item} index={index} onLearnMoreClick={openCalendlyPopup} />
        ))}
      </div>
      {openCalendly && (
        <>
          <CalendaryPopup />
          <button
            className="fixed top-0 right-0 m-10"
            onClick={() => setOpenCalendly(false)}
            style={{
              zIndex: 205,
              width: "45px",
              height: "45px",
              borderRadius: "50%",
            }}
          >
            <CloseIcon htmlColor="white" />
          </button>
        </>
      )}
    </div>
  );
};

export default SolutionSection;
