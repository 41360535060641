import React from "react";
import ReactDOM from "react-dom/client";
import { FronteggProvider } from "@frontegg/react";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";

const development = {
  clientId: "2ce0d581-48c1-4348-a0ab-05e1d0b63b60",
  baseUrl: "https://app-9u2ynhe3mc1d.frontegg.com",
};

const production = {
  clientId: "cf890130-015c-41b0-bd3d-ea03fa393b41",
  baseUrl: "https://illustria.frontegg.com",
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <FronteggProvider contextOptions={production} hostedLoginBox>
        <App />
      </FronteggProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
