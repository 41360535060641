import React from "react";
import { AnimatePresence } from "framer-motion";

import arrowRight from "../../assets/icons/arrow-right.svg";
import closeIcon from "../../assets/icons/close.svg";

const FloatingBanner = ({ isVisible, onClick, onClose }) => {
  return isVisible ? (
    <div className={`w-full min-w-[393px] relative flex flex-row items-center justify-center sm:h-[56px] h-[80px] bg-banner sm:px-[32px] px-[16px]`}>
      <p className="flex-1 text-[16px] text-left sm:text-center text-[white] border-b border-transparent">
        Read more about supply chain attacks within our{" "}
        <span onClick={onClick} className="font-bold cursor-pointer inline-block border-b border-transparent hover:border-b hover:border-white">
          latest blog post <img alt="arrow-right" src={arrowRight} width={"24px"} height={"24px"} className="inline-block ml-2 mt-[-2px]" />
        </span>
      </p>
      <img src={closeIcon} alt="close" className="cursor-pointer" onClick={onClose} width={"24px"} height={"24px"} />
    </div>
  ) : null;
};

export default FloatingBanner;
