import React from "react";

const FeedbackCard = ({
  name,
  title,
  profileImg,
  content,
  logo,
  altLogo,
  index,
}) => {
  const renderContent = () => {
    const parts = content.split("**");
    return parts.map((part, index) => {
      return index % 2 === 1 ? (
        <strong key={index}>{part}</strong>
      ) : (
        <span key={index}>{part}</span>
      );
    });
  };

  return (
    <div
      className={` ${
        index === 3 ? "hidden sm:flex" : ""
      }  xl:h-[210px] md:h-[250px] max-w-[787px] border border-[#CBCBCB] flex flex-col rounded-xl md:gap-[27px]  md:p-[22px] p-[16px]`}
    >
      <div className="mb-4">
        <p className="leading-[24px] opacity-80">{renderContent()}</p>
      </div>

      <div>
        <div className="h-[1px] bg-[#CBCBCB] lg:w-full md:block hidden mb-[25px]" />
        <div className="md:items-center flex md:flex-row flex-col-reverse  md:gap-[16px] gap-[15px]  ">
          <div className="flex gap-3">
            <img src={profileImg} alt="profileImg" />
            <div className="flex flex-col">
              <h5 className="font-bold text-orange text-[14px] lg:text-[16px]">
                {name}
              </h5>
              <p className="text-[10px] lg:text-[12px]">{title}</p>
            </div>
          </div>
          <div className="h-[40px] bg-[#979797] w-[1px] hidden md:block" />
          <div className=" block md:hidden border-t border-[#CBCBCB]" />
          <div>
            <img
              src={logo}
              alt="logo"
              className={`hidden md:block ${index === 1 ? "w-[137px]" : ""} ${
                index === 2 ? "w-[126px]" : ""
              }`}
            />
            <img
              src={altLogo ? altLogo : logo}
              alt="logo"
              className={`block md:hidden ${index === 1 ? "w-[137px]" : ""} ${
                index === 2 ? "w-[98px] h-[40px]" : ""
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackCard;
