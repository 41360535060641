const styles = {
  title: "font-semibold lg:text-[24px] capitalize text-[20px] mb-[32px] lg:mb-[40px] leading-[29px]",
  productContainer: "flex flex-col gap-[30px]",
  productsList: "flex flex-col md:flex-row gap-[57px] lg:justify-between",
  productTitle: "text-[#7E8492] font-semibold lg:text-[24px] text-[20px] capitalize",
  attackVectorSection: "flex flex-col p-[23px] bg-light shadow-[0px_0px_15px_0px_rgba(0,0,0,0.04)] rounded-[12px] lg:shadow-none lg:rounded-0 lg:bg-transparent lg:p-0",
  attackVectorContainer: "flex flex-col items-start justify-between w-full xs:w-[308px]",
  attackVectorNumber: "font-semibold text-[18px] text-[#7E8492] mb-[13px] leading-[22px]",
  attackVectorTitle: "font-semibold text-[18px] text-[#7E8492] mb-[13px] leading-[22px]",
  attackVectorDescription: "font-normal text-[17px] text-[#7E8492] leading-[155%] flex-1 w-[92%] ",
};

export default styles;
