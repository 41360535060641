import React, { useEffect, useRef, useState } from "react";
import { CaseStudy, Contact, Footer, Hero, Integrations, Navbar, Solution, Testimonials } from "../components";
import TrustedBy from "../components/main/TrustedBy";
import HeroSection from "../components/main/HeroSection/HeroSection";
import commonStyles from "../styles";
import ProblemSection from "../components/main/ProblemSection/ProblemSection";
import SolutionSection from "../components/main/SolutionSection/SolutionSection";
import IndustryInitiative from "../components/main/IndustryInitiative/IndustryInitiative";
import TheProduct from "../components/main/ProblemSection/TheProduct";
import { throttle } from "../utils/common";

const HomePage = () => {
  const [active, setActive] = useState("overview");
  const [isBannerRecentlyClosed, setIsBannerRecentlyClosed] = useState(false);
  const sections = useRef([]);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    sections.current = document.querySelectorAll("[data-section]");
    let scrollTimeout;

    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 100); // Adjust the timeout as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      let mostVisibleSection = null;
      let highestVisibility = 0;

      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0 && !isScrolling) {
          const visibility = entry.intersectionRatio;
          if (visibility > highestVisibility) {
            highestVisibility = visibility;
            mostVisibleSection = entry.target;
          }
        }
      });

      if (mostVisibleSection) {
        setActive(mostVisibleSection.id);
      }
    };

    const throttledHandleIntersection = throttle(observerCallback, 100);

    const observer = new IntersectionObserver(throttledHandleIntersection, observerOptions);
    sections.current.forEach((section) => observer.observe(section));

    return () => {
      sections.current.forEach((section) => observer.unobserve(section));
    };
  }, [isScrolling, sections]);

  return (
    <div>
      <Navbar active={active} setActive={setActive} isBannerRecentlyClosed={isBannerRecentlyClosed} setIsBannerRecentlyClosed={setIsBannerRecentlyClosed} />

      <div className="w-full flex flex-col justify-center items-center">
        <div className="w-full">
          <HeroSection setActive={setActive} isBannerRecentlyClosed={isBannerRecentlyClosed} />
          <TrustedBy />
        </div>

        <div className={`${commonStyles.responsive.sectionContainer}`}>
          <Testimonials />
        </div>

        <div className={`w-full lg:block hidden`}>
          <CaseStudy />
        </div>

        <div className="w-full lg:bg-problemSectionBackgroundMedium xxl:bg-problemSectionBackground flex justify-center">
          <div className={`${commonStyles.responsive.sectionContainer} bg-gray_bg md:bg-transparent `}>
            <div className="py-[26px] lg:pt-[100px] lg:pb-[100px] flex flex-col">
              <TheProduct setActive={setActive} />
              <ProblemSection setActive={setActive} />
            </div>
          </div>
        </div>

        <div className={`${commonStyles.responsive.sectionContainer}`}>
          <SolutionSection setActive={setActive} />
        </div>

        <div className="w-full">
          <Contact />
        </div>

        <div className="w-full bg-[#F6F6F6] flex justify-center">
          <div className={`${commonStyles.responsive.sectionContainer} `}>
            <IndustryInitiative />
          </div>
        </div>

        <div className={`${commonStyles.responsive.sectionContainer}`}>
          <Integrations />
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
