import styles from "../../styles";
import { Divider } from "@mui/material";
import logoLight from "../../assets/logos/logoWhite.png";
import visibleAlpha from "../../assets/logos/visibleAlphaLight.png";

const CaseStudyMain = () => {
  return (
    <section
      className={`w-full rounded-lg flex justify-center md:bg-caseStudy bg-mobileOrange bg-cover xxl:px-[410px] xl:px-[255px] lg:px-[84px] px-[34px] md:py-[134px] py-[50px]`}
    >
      <div className=" w-full">

      
      <div className="flex flex-col gap-[32px] ">
        <div className={`flex flex-row gap-5 items-center`}>
          <img src={logoLight} alt="illustria" className="w-1/3 md:w-auto" />
          <Divider
            flexItem
            orientation="vertical"
            sx={{ background: "white" }}
          />
          <img
            src={visibleAlpha}
            alt="visible_alpha"
            className="w-1/2 md:w-auto"
          />
        </div>
        <Divider flexItem sx={{ background: "#FFFFFF8C" }} />
        <p className="text-[36px] leading-[46px] text-[#FFFFFF]">
          Visible Alpha, a New York-based company, is dedicated to 
          <strong> developing </strong>
          and{" "}
          <strong>
            providing solutions for the current and future landscape of
            investment research.
          </strong>
        </p>
        <Divider flexItem sx={{ background: "#FFFFFF8C" }} />
        <div className="flex flex-col sm:flex-row items-start sm:items-center ">
          <p className={`${styles.caseStudy.button.text}`}>
            <span className="opacity-70">REGION</span> <strong>GLOBAL</strong>
          </p>
          <Divider flexItem orientation="vertical" sx={{ background: "#FFFFFF8C", mx: "22px" }} />
          <p className={`${styles.caseStudy.button.text}`}>
            {" "}
            <span className="opacity-70">INDUSTRY</span>{" "}
            <strong>Financial Services</strong>
          </p>
        </div>
      </div>
      </div>
    </section>
  );
};

export default CaseStudyMain;
