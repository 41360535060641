import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Squash as Hamburger } from "hamburger-react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import chip from "../../assets/backgroundImages/chip.png";
import { logo } from "../../assets";

import { navLinks } from "../../constants";
import Calendary from "../utils/Calendary";
import FloatingBanner from "./FloatingBanner";
import styles from "../../styles";

const Navbar = ({ active, setActive, isBannerRecentlyClosed, setIsBannerRecentlyClosed }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const scrollTo = (id, title, offset = 0) => {
    if (id === "logo") {
      navigate("/");

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setActive("overview");
      return;
    }

    const element = document.getElementById(id);
    if (element) {
      const offsetTop = element.offsetTop - offset;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
      setActive(id);
    }
  };

  const goToBlog = () => {
    window.location.href = "https://blog.illustria.io/";
    if (window.innerWidth <= 699) {
      document.location = "https://blog.illustria.io/";
    }
  };

  useEffect(() => {
    if (isBannerRecentlyClosed) {
      return;
    }
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [isBannerRecentlyClosed]);

  const listenToScroll = () => {
    if (!isBannerRecentlyClosed) {
      let heightToHideFrom = 700;
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

      if (winScroll > heightToHideFrom) {
        isVisible && setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }
  };

  const closeBanner = () => {
    setIsVisible(false);
    setIsBannerRecentlyClosed(true);
    setTimeout(() => {
      setIsBannerRecentlyClosed(false);
    }, 3600000);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && isNavOpen) {
        setIsNavOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isNavOpen]);

  useEffect(() => {
    if (location.state && location.state.sectionId) {
      const { sectionId, title } = location.state;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView();
        setActive(sectionId);
      }
    }
  }, [location.state]);

  return (
    <div className="w-full fixed z-[40]">
      <FloatingBanner isVisible={isVisible} onClick={goToBlog} onClose={closeBanner} />

      <nav className={`flex justify-center bg-gray_bg xl:bg-light h-[70px] w-full `}>
        <div className={`h-[70px] w-full max-w-[1654px] flex justify-between items-center lg:px-[32px] px-6`}>
          <img src={logo} alt="logo" onClick={() => scrollTo("overview", "Overview")} className="cursor-pointer" />
          <ul className="h-[70px] gap-10 hidden lg:flex relative">
            {navLinks.map((item) => (
              <div key={item.id} className="flex flex-col gap-[18px] items-center">
                {active === item.id && <div className="w-[50px] h-[5px] bg-orange rounded-tl-none rounded-tr-none rounded-b-sm " />}
                <li
                  className={`cursor-pointer ${active === item.id ? `${styles.active} ` : "font-medium text-[16px] pt-[23px]"}`}
                  onClick={() => {
                    scrollTo(item.id, item.title, 100);
                    setIsNavOpen(false);
                  }}
                >
                  {item.title}
                </li>
                {item.chip && <img src={chip} />}
              </div>
            ))}
          </ul>

          {/* Burger menu */}
          <div className="lg:hidden">
            <Hamburger toggled={isNavOpen} onToggle={() => setIsNavOpen((prevState) => !prevState)} />
          </div>
          <motion.div
            ref={sidebarRef}
            initial={{ x: "100%" }}
            animate={{ x: isNavOpen ? 0 : "100%" }}
            transition={{ type: "tween", duration: 0.3 }}
            className="fixed top-0 right-0 bottom-0 bg-white w-full z-50 shadow-md overflow-y-auto"
          >
            <ul className="py-8 px-4">
              <img src={logo} alt="logo" onClick={() => scrollTo("logo")} className="cursor-pointer" style={{ position: "absolute", top: "10px", left: "10px" }} />
              <CloseIcon onClick={() => setIsNavOpen(false)} sx={{ position: "absolute", top: "15px", right: "10px" }} />

              {navLinks.map((item, index) => (
                <div key={item.id}>
                  <li
                    className={` mt-10 cursor-pointer ${active === item.id ? `${styles.active}` : "font-medium text-[16px]"}`}
                    onClick={() => {
                      scrollTo(item.id, item.title, 100);
                      setIsNavOpen(false);
                    }}
                  >
                    {item.title}
                  </li>
                  {index !== navLinks.length - 1 && <Divider sx={{ my: "31px" }} />}
                </div>
              ))}

              <div className="md:hidden mt-[31px]">
                <Calendary />
              </div>
            </ul>
          </motion.div>
          <div className=" flex-row gap-[16px] hidden lg:flex">
            <Link to="/console">
              <button className="h-[44px] w-[99px] rounded-lg px-[15px] text-base  lg:text-[16px] border border-[#CBCBCB]  bg-light font-bold  text-black hover:opacity-60">Console</button>
            </Link>
            <div>
              <Calendary />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
