import React from "react";
import { useRoutes } from "react-router-dom";

import Paths from "./paths";
import HomePage from "../pages/HomePage";
import { CaseStudyPage } from "../components";
import TenentConsolePage from "../pages/TenentConsolePage";

const Router = () => {
  return useRoutes([
    {
      path: Paths.HOME,
      element: <HomePage />,
    },
    {
      path: Paths.CASE_STUDY,
      element: <CaseStudyPage />,
    },
    {
      path: Paths.CONSOLE,
      element: <TenentConsolePage />,
    },
  ]);
};

export default Router;
