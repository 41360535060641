import {
  Contact,
  CaseStudyMain,
  BussinessChallenge,
  CaseData,
  CaseStudySolution,
  CaseStudyTestimonials,
} from "../components";
import styles from "../styles";

const CaseStudyPage = () => {
  return (
    <div className="bg-gray_bg flex flex-col justify-center items-center rounded-lg ">
      <div className="w-full">
        <CaseStudyMain />
      </div>

      <div className={`${styles.screens}`}>
        <BussinessChallenge />
        <CaseData />
        <CaseStudySolution />
        <CaseStudyTestimonials />
        <Contact />
      </div>
    </div>
  );
};

export default CaseStudyPage;
