import React, { useEffect } from "react";
import circularCancel from "../../assets/icons/circularCancel.svg";

const CaseStudyModal = ({ onClose, modalRef, children }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleModalScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      e.stopPropagation();
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex flex-col items-center  justify-center bg-black bg-opacity-50 py-6">
      <div className={` w-[88%] flex justify-end `}>
        <img
          onClick={onClose}
          src={circularCancel}
          alt="circularCancel"
          className="self-end cursor-pointer"
          width={"45px"}
          height={"45px"}
        />
      </div>

      <div
        ref={modalRef}
        className="p-4 rounded-lg relative mt-2"
        style={{
          width: "90%",
          maxHeight: "100vh",
          overflow: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: "transparent transparent",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
        }}
        onScroll={handleModalScroll}
      >
        {children}
      </div>
    </div>
  );
};

export default CaseStudyModal;