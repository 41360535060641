import React from "react";
import styles from "../../styles";

const CaseData = () => {
  return (
    <div className={`${styles.caseStudy.container} lg:mt-[-5rem] bg-gray_bg`}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <div className={`${styles.caseStudy.dataBox} px-20 bg-[#F0F0F0]`}>
          <h1 className="text-orange text-5xl md:text-7xl font-bold leading-[84px] mb-3">
            {">65%"}
          </h1>
          <p className={`${styles.caseStudy.bussinessChallenge.paragraph}`}>
            More risks identified in the SW supply chain than other reviewed tools
          </p>
        </div>
        <div className={`${styles.caseStudy.dataBox} px-20 bg-white`}>
          <h1 className="text-orange text-2xl md:text-4xl font-bold leading-[42px] mb-3">
            Substantial Reduction
          </h1>
          <p className={`${styles.caseStudy.bussinessChallenge.paragraph}`}>
            In vulnerabilities has resulted from this implementation
          </p>
        </div>
        <div className={`${styles.caseStudy.dataBox} px-20 bg-[#F0F0F0]`}>
          <h1 className="text-orange text-5xl md:text-7xl font-bold leading-[84px] mb-3">
            {">20%"}
          </h1>
          <p className={`${styles.caseStudy.bussinessChallenge.paragraph}`}>
            Reduction in mitigation time.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CaseData;
