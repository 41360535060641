import React from "react";
import styles from "../../styles";

const BussinessChallenge = () => {
  return (
    <div className={`${styles.caseStudy.container} bg-gray_bg `}>
      <div className="flex gap-3.5 items-center mb-5">
        <div className="w-[2px] h-[30px] border border-orange" />
        <h1 className={`${styles.caseStudy.bussinessChallenge.header}`}>
          Business challenge
        </h1>
      </div>
      <h3 className={`${styles.caseStudy.bussinessChallenge.headline} mb-5 `}>
        Visible Alpha is committed to securing its development <br /> lifecycle
        and dependencies.{" "}
      </h3>
      <div className="flex flex-col md:flex-row md:gap-[80px] gap-[40px]">
        <p
          className={`${styles.caseStudy.bussinessChallenge.paragraph} w-[95%]`}
        >
          Their commitment is to protect both our IP and the IP of their clients
          and data providers. Visible Alpha’s development teams are core to what
          they do and the supply chain security of the code we develop is
          paramount to ensuring security.
        </p>
        <p
          className={`${styles.caseStudy.bussinessChallenge.paragraph} w-[95%]`}
        >
          With around a hundred developers and dozens of production code
          repositories. Managing and monitoring the dependencies of being used
          in so many places was a nearly impossible challenge.
        </p>
      </div>
     
    </div>
  );
};

export default BussinessChallenge;
