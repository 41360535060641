import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styles from "../../styles";
import { armis, isracard, naviga, sapiens, silverfort, visibleAlpha } from "../../assets/trustedLogos";

const images = [isracard, visibleAlpha, sapiens, naviga, silverfort, armis];

const TrustedBy = () => {
  const duplicatedSlides = [...images, ...images, ...images];
  const [goalNum, setGoalNum] = useState(120);

  useEffect(() => {
    const calculateGoalNum = () => {
      const screenWidth = window.innerWidth;
      const desiredSlideWidth = 122;

      let newGoalNum = (desiredSlideWidth / screenWidth) * 1440;
      if (newGoalNum < 120) {
        newGoalNum = 120;
      }

      setGoalNum(newGoalNum);
    };

    calculateGoalNum();

    window.addEventListener("resize", calculateGoalNum);

    // Cleanup
    return () => window.removeEventListener("resize", calculateGoalNum);
  }, []);

  return (
    <div className="bg-gray_bg flex flex-col justify-center md:h-[200px] h-[160px]">
      <div className={`w-full xxl:max-w-[1590px] self-center overflow-hidden lg:px-[84px] px-[34px]`}>
        <motion.div
          className="relative items-center h-[54px] gap-[114px] hidden md:flex"
          data-testid="trusted-by-logos"
          animate={{
            x: [`-${goalNum}%`, `${-goalNum + 100}%`],
            transition: {
              ease: "linear",
              duration: 30,
              repeat: Infinity,
            },
          }}
        >
          {duplicatedSlides.map((slide, index) => (
            <div key={index} className="flex-shrink-0" style={{ width: 122 }}>
              <img src={slide} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </motion.div>
        <motion.div
          className="relative flex items-center h-[54px] gap-[114px] md:hidden"
          data-testid="trusted-by-logos"
          animate={{
            x: [`-${goalNum}%`, `0%`],
            transition: {
              ease: "linear",
              duration: 30,
              repeat: Infinity,
            },
          }}
        >
          {duplicatedSlides.map((slide, index) => (
            <div key={index} className="flex-shrink-0" style={{ width: 122 }}>
              <img src={slide} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default TrustedBy;
