import React from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import styles from "../../styles";

const SolutionCard = ({ solutionImg, solutionTitle, solutionContent, index, onLearnMoreClick }) => {
  return (
    <div className={`flex md:flex-row md:gap-[124px] gap-[24px] flex-col  ${index === 1 ? "md:flex-row-reverse" : ""}  items-center`}>
      <img src={solutionImg} alt="solutionImg" className={`md:w-1/2 w-full shadow-[0px_0px_32.61px_0px_rgba(0,0,0,0.04)] max-w-[592px] bg-white`} />
      <div className="flex flex-col ">
        <h1 className={`${styles.headline} lg:mb-1 mb-2`}>{solutionTitle}</h1>
        <p className="leading-[140%] opacity-60 md:text-[18px] lg:mt-3 lg:w-[80%] mb-[21px]">{solutionContent}</p>
        <button className="w-fit flex flex-row align-center gap-3 text-[18px] text-[#F26800] leading-[140%]" onClick={onLearnMoreClick}>
          <ArrowForwardIcon className="w-[10px] h-[10px]" />
          Learn More
        </button>
      </div>
    </div>
  );
};

export default SolutionCard;
