import commonStyles from "../../../styles";

const styles = {
  container: `${commonStyles.responsive.sectionContainer} lg:pt-[90px] xxl:pt-[140px] flex flex-col items-center lg:flex-row justify-between`,
  heroTitle: `text-[#090722] text-[32px] font-bold leading-[35.36px] lg:w-[470px] lg:text-[54px] leading-[59.94px] xl:w-[540px] xl:text-[62px] leading-[68.51px]`,
  heroText: `text-[18px] leading-[24.3px] lg:w-[492px] lg:text-[24px] leading-[32.4px]`,
  caseStudyButton: `flex gap-2 items-center justify-center rounded-lg bg-[#10B98133] px-2 py-[10px] text-[#090722] text-center font-medium leading-[24px] hover:opacity-80`,
  buttonSize: `w-full max-w-[393px] lg:w-[171px]`,
  videoContainer: "w-full h-full rounded-xl hidden md:block md:w-[560px] xl:w-[750px]",
  videoContainerMobile: "w-full h-full rounded-xl md:hidden",
};

export default styles;
