import styles from "../../styles";

const Solution = () => {
  return (
    <div className={`${styles.caseStudy.container} bg-gray_bg lg:mt-[-5rem]`}>
      <div className="flex gap-3.5 items-center mb-5">
        <div className="w-[2px] h-[30px] border border-orange" />
        <h1 className={`${styles.caseStudy.bussinessChallenge.header}`}>
          Solution
        </h1>
      </div>
      <h3 className={`${styles.caseStudy.bussinessChallenge.headline} mb-5 `}>
        Visible Alpha’s Head of Information Security, David Smith, chose
        Illustria as their Cybersecurity Supply Chain Risk Management solution.{" "}
      </h3>
      <div className="flex flex-col md:flex-row md:gap-[87px] gap-[40px]">
        <p
          className={`${styles.caseStudy.bussinessChallenge.paragraph} `}
        >
          Illustria offers a comprehensive platform to identify and manage
          issues in the Software Supply Chain which goes beyond simple
          identification of known vulnerabilities, In the search of both Open
          Source and commercial tools Visible Alpha found Illustria to offer the
          most flexibility and better overall identification of issues as
          compared to other open source and commercial tools.
        </p>
        <p
          className={`${styles.caseStudy.bussinessChallenge.paragraph} `}
        >
          The Illustria platform allows Visible Alpha to quickly identify,
          monitor, manage, and mitigate risks in the supply chain. The
          flexibility of of Illustria in being able to work in the CI/CD process
          as well as in individual developer’s IDE’s allows illustria to shift
          left and have issues resolved before they ever make it to CI/CD.
          Additionally, when for example new vulnerabilities emerge they can
          also be caught in the CI/CD process and resolved there.
        </p>
      </div>
    </div>
  );
};

export default Solution;
