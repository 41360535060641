import React from "react";
import styles from "../../styles";
import davidSmith from "../../assets/personnel/davidSmith.png";
import { Divider } from "@mui/material";
import caseStudyVisibleAlpha from "../../assets/logos/caseStudyVisibleAlpha.png";
import caseStudyTestimonials from "../../assets/backgroundImages/caseStudyTestmonial.png";

const Testimonials = () => {
  const divStyle = {
    backgroundImage: `url(${caseStudyTestimonials})`,
  };

  return (
    <div
      style={divStyle}
      className={`${styles.caseStudy.container} py-12 px-10 bg-gray_bg md:mb-12`}
    >
      <div className="flex flex-col justify-center items-center">
        <div className="bg-white max-w-[780px] py-9 md:px-[64px] px-[30px]">
          <p className="text-lg md:text-2xl leading-[35px] md:text-center mb-[43px]">
            “Unlike most other tools and platforms we reviewed,{" "}
            <strong>Illustria's capability to do a deep dive</strong> into each
            dependency was able to surface issues and get those resolved
            quickly.<strong>Other platforms simply did not.</strong>”
          </p>
          <div className="flex flex-col md:flex-row items-center gap-[20px] md:gap-[40px]">
            <div className="md:block hidden">
              <TestimonialCard />
            </div>
            <div className="md:hidden block">
              <TestimonialCardMobile />
            </div>
            <Divider className="hidden md:flex" flexItem orientation="vertical" />
            <Divider flexItem className="md:hidden block " />
            <img
              src={caseStudyVisibleAlpha}
              alt="visibleAlpha"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

const TestimonialCard = () => {
  return (
    <div className="flex gap-[15px] items-center">
      <img
        src={davidSmith}
        alt="davidSmith"
        className="w-[57.63px] h-[57.63px]"
      />
      <div className="flex flex-col">
        <h1 className="text-[#425466] font-bold leading-[28px] text-[20px]">
          David Smith
        </h1>
        <p className="leading-[28px] text-[18px] text-[#425466CC]">
          Head of Information Security
        </p>
      </div>
    </div>
  );
};

const TestimonialCardMobile = () => {
  return (
    <div className="flex flex-col gap-[15px] items-center">
      <div className="flex items-center gap-2">
        <img
          src={davidSmith}
          alt="davidSmith"
          className="w-[57.63px] h-[57.63px]"
        />
        <h1 className="text-[#425466] font-bold leading-[28px] text-[16px]">
          David Smith
        </h1>
      </div>

      <Divider flexItem />
      <p className="leading-[28px] text-[16px] text-[#425466CC] w-full">
        Head of Information Security
      </p>
    </div>
  );
};
