import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import styles from "../../styles";
import CaseStudyModal from "../utils/CaseStudyModal";
import CaseStudyPage from "../../pages/CaseStudyPage";

const MobileView = ({ openCaseStudy }) => {
  return (
    <div className={`h-[197px] py-[46px] px-[24px] flex flex-col lg:hidden`}>
      <span className="w-[56px] text-[16px] text-white border border-white px-[9px] py-[2px] rounded-[99px] mb-[14px]" style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}>
        NEW
      </span>
      <p className="text-[22px] text-start font-medium text-white ">
        Dive into the <strong>Visible Alpha case study</strong> and discover <br className="hidden lg:block" /> how we
        <strong> revolutionized</strong> their approach.
      </p>
      <button className={"flex justify-center items-center bg-orange text-white w-full h-[44px] py-[10px] px-[15px] rounded-[10px] mt-[17px]"} onClick={openCaseStudy}>
        <ArrowForwardIcon className="w-[10px] h-[10px] ml-[-1rem]" />
        Explore Now
      </button>
    </div>
  );
};

const DesktopView = ({ openCaseStudy }) => {
  return (
    <div className={`w-full h-[180px] px-[80px] flex flex-row justify-between items-center min-[2560px]:hidden max-[1023px]:hidden`}>
      <div className="flex flex-col">
        <p className="text-[22px] text-start font-medium text-white ">
          Dive into the <strong>Visible Alpha case study</strong> and discover
        </p>
        <div className="flex flex-row items-center gap-2">
          <p className="text-[22px] text-start font-medium text-white ">
            how we <strong> revolutionized</strong> their approach.
          </p>
          <span className="w-[56px] text-[16px] text-white border border-white px-[9px] py-[2px] rounded-[99px]" style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}>
            NEW
          </span>
        </div>
      </div>

      <button className={"flex justify-center items-center gap-1.5 bg-white text-black font-bold w-[189px] h-[44px] py-[14px] px-[28px] rounded-[10px]"} onClick={openCaseStudy}>
        <ArrowForwardIcon className="w-[10px] h-[10px] ml-[-1rem]" />
        Explore Now
      </button>
    </div>
  );
};

const WideView = ({ openCaseStudy }) => {
  return (
    <div className={`w-full h-[260px] px-[80px] flex flex-row justify-center max-[2559px]:hidden`}>
      <div className="w-[1590px] flex flex-row justify-between items-center">
        <div className="flex flex-col">
          <p className="text-[28px] text-start font-medium text-white ">
            Dive into the <strong>Visible Alpha case study</strong> and discover
          </p>
          <div className="flex flex-row items-center gap-2">
            <p className="text-[28px] text-start font-medium text-white ">
              how we <strong> revolutionized</strong> their approach.
            </p>
            <span className="w-[56px] text-[16px] text-white border border-white px-[9px] py-[2px] rounded-[99px]" style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}>
              NEW
            </span>
          </div>
        </div>

        <button className={"flex justify-center items-center gap-1.5 bg-white text-black text-[22px] font-bold w-[242px] h-[61px] py-[14px] px-[28px] rounded-[10px]"} onClick={openCaseStudy}>
          <ArrowForwardIcon className="w-[33px] h-[33px]" />
          Explore Now
        </button>
      </div>
    </div>
  );
};

const CaseStudy = () => {
  const [caseStudyModalOpen, setCaseStudyModalOpen] = useState(false);
  const caseStudyModalRef = useRef(null);
  const navigate = useNavigate();

  const openCaseStudy = () => {
    setCaseStudyModalOpen(true);
  };

  const closeCaseStudy = () => {
    setCaseStudyModalOpen(false);
  };

  return (
    <div className={`w-full h-[290px] lg:h-[180px] xxl:h-[260px] bg-cover bg-explorMoreBackgroundMobile lg:bg-explorMoreBackground2k xxl:bg-[#22203A] xxl:bg-explorMoreBackgroundDesktop`}>
      <MobileView openCaseStudy={openCaseStudy} />
      <DesktopView openCaseStudy={openCaseStudy} />
      <WideView openCaseStudy={openCaseStudy} />

      {caseStudyModalOpen && (
        <CaseStudyModal onClose={closeCaseStudy} modalRef={caseStudyModalRef}>
          <CaseStudyPage />
        </CaseStudyModal>
      )}
    </div>
  );
};

export default CaseStudy;
