const styles = {
  container: "w-full flex flex-col lg:flex-row justify-between items-center gap-6",
  card: "h-[348px] w-[345px] lg:w-[410px] xxl:w-[514px] flex flex-col rounded-[12px] bg-light overflow-hidden",
  image: "h-[207px] w-full",
  imageStyles: {
    objectFit: "cover",
    objectPosition: "center",
  },
  textContainer: "h-[144px] flex flex-col justify-between p-4 gap-4",
  text: "text-[20px] leading-[29px] font-medium",
  button: "w-fit px-3 py-[6px] border border-[#CBCBCB] rounded-[6px] text-[16px] font-[#4B5563] font-medium",
};

export default styles;
