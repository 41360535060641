import React from "react";
import FeedbackCard from "../utils/FeedbackCard";
import { feedback } from "../../constants";
import { motion } from "framer-motion";
import styles from "../../styles";

const Testimonials = () => {
  return (
    <div className={`w-full bg-white flex md:flex-row flex-col md:justify-center items-start  py-[45px]`}>
      <div className="w-full">
        <h1 className={`${styles.headline}`}>Testimonials</h1>
        <div className="md:grid md:grid-cols-2 gap-5 flex flex-col ">
          {feedback.map((item, index) => (
            <motion.div whileInView={{ y: [100, 0], opacity: [0, 1] }} transition={{ duration: 0.5 }} key={item.id}>
              <FeedbackCard key={item.id} {...item} index={index} />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
