import React, { useEffect, useState } from "react";
import { useTenantsState, useAuth, useLoginWithRedirect } from "@frontegg/react";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../components/ui/common/Spinner";

const AuthManager = ({}) => {
  const { user, isAuthenticated } = useAuth();
  const { tenants } = useTenantsState();
  const loginWithRedirect = useLoginWithRedirect();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      const redirectUrl = `${window.location.origin}/console`;
      localStorage.setItem("_REDIRECT_AFTER_LOGIN_", redirectUrl);
      loginWithRedirect({
        redirectUrl: redirectUrl,
      });
    } else {
      if (user && tenants && tenants.length > 0) {
        setError(null);
        const { tenantId } = user;
        if (!tenantId) {
          setError("No tenantId");
          return;
        }
        let found = false;

        tenants.forEach((tenant) => {
          if (tenant.tenantId === user.tenantId) {
            if (tenant.metadata) {
              const metadata = JSON.parse(tenant.metadata);
              const { instance } = metadata;
              if (!instance) {
                setError("No instance found");
                return;
              }
              const { url } = instance;

              window.location.href = url;
              found = true;
            } else {
              setError("No metadata ");
              return;
            }
          }
        });
        if (!found) {
          setError("No tenant found");
        }
      }
    }
  }, [isAuthenticated, user, tenants]);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      {false ? (
        <div className="flex flex-col justify-center items-center p-4 shadow-2xl rounded-lg gap-4">
          <div className="bg-gray_bg px-4 py-2 rounded-full">
            <div className="flex flex-row items-center gap-2">
              <img src={user?.profilePictureUrl} alt={user?.name} style={{ width: 44, height: 44, borderRadius: 22 }} />
              <div className="flex flex-col gap-[4px] justify-start">
                <p className="text-[16px] font-normal leading-[120%]">{user?.name}</p>
                <p className="text-[16px] text-gray_01 leading-[120%]">{user?.email}</p>
              </div>
            </div>
          </div>

          {error && <p className="text-red-500">{error}</p>}

          {/* <div className="flex flex-col w-full gap-2">
            <h1 className="text-[16px] font-bold leading-[27.6px]">Instances</h1>
            {tenantMetadata && tenantMetadata?.instance?.url ? (
              <a href={tenantMetadata.instance.url} className="w-full">
                <button className="w-full p-2 rounded-lg bg-gray_bg hover:bg-gray_02 rounded-lg px-2 py-[10px] text-[#090722] text-center font-medium leading-[24px]">
                  {tenantMetadata?.instance?.name || "Instant"}
                </button>
              </a>
            ) : (
              <p className="w-full p-2  rounded-lg px-2 py-[10px] text-[#090722] text-center font-medium leading-[24px]">No Instances</p>
            )}
          </div> */}
        </div>
      ) : (
        <div className="w-[250px] h-[250px] overflow-hidden flex justify-center items-center">
          <Spinner color={"text-orange"} size={16} />
        </div>
      )}
    </div>
  );
};

const ConsolePage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [contextOptions, setContextOptions] = useState(null);

  return <AuthManager contextOptions={contextOptions} />;
};

export default ConsolePage;
