import React from "react";

import { integrationsMobile } from "../../assets";
import newIntegrations from "../../assets/images/integrations.svg";

import commonsStyles from "../../styles";

const Integrations = () => {
  return (
    <div className={`w-full py-0 md:py-[76px] `}>
      <div className={`flex flex-col flex-start `}>
        <p className={`${commonsStyles.headline}`}>Some of our integrations...</p>
        <div className="flex items-center w-full">
          <img id="about" src={newIntegrations} alt="integrations" className="hidden sm:block w-full" />
          <img src={integrationsMobile} alt="integrationsMobile" className="sm:hidden w-full" />
        </div>
      </div>
    </div>
  );
};

export default Integrations;
