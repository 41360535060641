import React from "react";

import AttackVector1 from "../../../assets/attackVector/attack-vector-1.svg";
import AttackVector1Mobile from "../../../assets/attackVector/attack-vector-1-mobile.svg";
import AttackVector2 from "../../../assets/attackVector/attack-vector-2.svg";
import AttackVector2Mobile from "../../../assets/attackVector/attack-vector-2-mobile.svg";
import AttackVector3 from "../../../assets/attackVector/attack-vector-3.svg";
import AttackVector3Mobile from "../../../assets/attackVector/attack-vector-3-mobile.svg";
import AttackVector4 from "../../../assets/attackVector/attack-vector-4.svg";
import AttackVector4Mobile from "../../../assets/attackVector/attack-vector-4-mobile.svg";
import AttackVectorSkeleton1 from "../../../assets/attackVector/attack-vector-skeleton-1.svg";
import AttackVectorSkeleton2 from "../../../assets/attackVector/attack-vector-skeleton-2.svg";

import styles from "./styles";
import commonStyles from "../../../styles";

const ProblemSection = ({ setActive }) => {
  return (
    <div data-section id="problem" className={`${styles.attackVectorSection}`}>
      <h1 className={`${commonStyles.headline}`}>Attack Vectors</h1>

      <div className={`flex flex-col md:flex-row md:justify-between items-center gap-[47px]`}>
        <div className={`${styles.attackVectorContainer}`}>
          <div className="h-[154px] mb-10">
            <p className={`${styles.attackVectorNumber}`}>01</p>
            <p className={`${styles.attackVectorTitle}`}>Typosquatting</p>
            <p className={`${styles.attackVectorDescription}`}>{`Developer mistypes a package name and gets a malicious package controlled by an attacker`}</p>
          </div>
          <img src={AttackVector1} alt="attack-vector-1" style={{ width: "284px", height: "218px" }} className="hidden md:block" />
          <img src={AttackVector1Mobile} alt="attack-vector-1-mobile" style={{ width: "299px", height: "238px" }} className="block md:hidden" />
        </div>

        <div className={`${styles.attackVectorContainer}`}>
          <div className="h-[154px] mb-10">
            <p className={`${styles.attackVectorNumber}`}>02</p>
            <p className={`${styles.attackVectorTitle}`}>Domain Takeover</p>
            <p className={`${styles.attackVectorDescription}`}>{`Open source maintainer forgets to renew their domain atacker then purchases the domain`}</p>
          </div>
          <img src={AttackVector2} alt="attack-vector-2" style={{ width: 284, height: 218 }} className="hidden md:block" />
          <img src={AttackVector2Mobile} alt="attack-vector-2-mobile" style={{ width: 299, height: 238 }} className="block md:hidden" />
        </div>

        <div className={`${styles.attackVectorContainer}`}>
          <div className="h-[154px] mb-10">
            <p className={`${styles.attackVectorNumber}`}>03</p>
            <p className={`${styles.attackVectorTitle}`}>Dependency Confusion</p>
            <p className={`${styles.attackVectorDescription}`}>{`The installation process gets a public package named after a private one, as the "latest" version.`}</p>
          </div>
          <img src={AttackVector3} alt="attack-vector-3" style={{ width: 284, height: 218 }} className="hidden md:block" />
          <img src={AttackVector3Mobile} alt="attack-vector-3-mobile" style={{ width: 299, height: 238 }} className="block md:hidden" />
        </div>

        <img src={AttackVector4Mobile} alt="attack-vector-3-mobile" style={{ width: 299, height: 186 }} className="block md:hidden mb-5" />

        <div className="flex flex-col justify-between w-[380px] h-[412px] hidden md:flex">
          <div className="flex flex-row justify-between h-[146px]">
            <img src={AttackVectorSkeleton1} alt="skeleton" className="w-[155px] h-[146px]" />
            <img src={AttackVectorSkeleton2} alt="skeleton" className="w-[155px] h-[146px]" />
          </div>
          <img src={AttackVector4} alt="attack-vector4" style={{ width: 380, height: 218 }} />
        </div>
      </div>
    </div>
  );
};

export default ProblemSection;
