import React from "react";

import productSast from "../../../assets/product/product-sast.svg";
import productSastMobile from "../../../assets/product/product-sast-mobile.svg";
import productSca from "../../../assets/product/product-sca.svg";
import productScaMobile from "../../../assets/product/product-sca-mobile.svg";
import productIllustria from "../../../assets/product/product-illustria.svg";
import IllustriaLogo from "../../../assets/logos/illustria-logo.svg";

import styles from "./styles";
import commonStyles from "../../../styles";

const TheProduct = ({ setActive }) => {
  return (
    <div data-section id="product" className="flex flex-col mb-8 md:mb-[100px]">
      <h1 className={`${commonStyles.headline}`}>The Product</h1>

      <div className={`${styles.productsList}`}>
        <div className={`${styles.productContainer}`}>
          <p className={`${styles.productTitle}`}>SAST</p>
          <img src={productSast} alt="sast" className="hidden md:block" />
          <img src={productSastMobile} alt="sast" className="block md:hidden" />
        </div>

        <div className={`${styles.productContainer}`}>
          <p className={`${styles.productTitle}`}>SCA</p>
          <img src={productSca} alt="sast" className="hidden md:block" />
          <img src={productScaMobile} alt="sast" className="block md:hidden" />
        </div>

        <div className={`${styles.productContainer}`}>
          <img src={IllustriaLogo} alt="logo" className={`w-[113.5px] h-[27.7px] md:w-[142px] md:h-[32px]`} />
          <img src={productIllustria} alt="illustria" />
        </div>
      </div>
    </div>
  );
};

export default TheProduct;
