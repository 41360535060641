import React, { useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import CalendaryPopup from "../utils/CalendaryPopup";

const MobileView = ({ setOpenCalendly }) => {
  return (
    <div className={`h-[420px] py-[100px] px-[24px] flex flex-col lg:hidden`}>
      <p className="text-[32px] text-start font-bold text-white leading-[115%]">Use open source responsibly.</p>
      <p className="text-[32px] text-start font-normal text-white leading-[115%]">Get started today with a free proof-of-value.</p>

      <button
        onClick={() => setOpenCalendly(true)}
        className="h-[44px] mt-[26px] py-[10px] px-[20px] flex justify-center items-center text-base font-bold text-[16px] rounded-[6px] outline-none bg-white text-orange"
      >
        <ArrowForwardIcon className="w-[10px] h-[10px] ml-[-1rem]" />
        Get a Demo
      </button>
    </div>
  );
};

const WideView = ({ setOpenCalendly }) => {
  return (
    <div className={`w-full h-[360px] px-[80px] flex flex-row justify-center max-[1023px]:hidden`}>
      <div className="w-[1590px] flex flex-row justify-between items-center">
        <div className="flex flex-col">
          <p className="text-[40px] text-start font-bold text-white leading-[145%]">Use open source responsibly.</p>
          <p className="text-[40px] text-start font-medium text-white leading-[145%]">Get started today with a free proof-of-value.</p>
        </div>

        <button
          onClick={() => setOpenCalendly(true)}
          className="h-[61.6px] py-[14px] px-[28px] flex justify-center items-center text-base font-bold text-[22px] rounded-[8.4px] outline-none bg-white text-orange"
        >
          <ArrowForwardIcon className="w-[10px] h-[10px] ml-[-1rem]" />
          Get a Demo
        </button>
      </div>
    </div>
  );
};

const Contact = () => {
  const [openCalendly, setOpenCalendly] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const formRef = useRef();

  return (
    <div className={`w-full h-[420px] md:h-[360px] bg-cover bg-contactSectionBackgroundMobile lg:bg-contactSectionBackgroundDesktop bg-[#F26800] xxl:bg-contactSectionBackground2k`}>
      <WideView setOpenCalendly={setOpenCalendly} />
      <MobileView setOpenCalendly={setOpenCalendly} />
      {openCalendly && (
        <>
          <CalendaryPopup />
          <button
            className="fixed top-0 right-0 m-10"
            onClick={() => setOpenCalendly(false)}
            style={{
              zIndex: 205,
              width: "45px",
              height: "45px",
              borderRadius: "50%",
            }}
          >
            <CloseIcon htmlColor="white" />
          </button>
        </>
      )}
    </div>
  );
};

export default Contact;
