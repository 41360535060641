import React, { useState, useRef } from "react";
import logoWhite from "../assets/logos/logoLight.png";
import { Divider } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import chip from "../assets/backgroundImages/chip.png";
import heart from "../assets/icons/heart.svg";
import CaseStudyModal from "./utils/CaseStudyModal";
import CaseStudyPage from "../pages/CaseStudyPage";

const Footer = () => {
  const [caseStudyModalOpen, setCaseStudyModalOpen] = useState(false);
  const caseStudyModalRef = useRef(null);

  const openCaseStudy = () => {
    setCaseStudyModalOpen(true);
  };

  const closeCaseStudy = () => {
    setCaseStudyModalOpen(false);
  };

  let date = new Date();
  const year = date.getFullYear();

  const links = {
    privacy: "https://app.termly.io/document/privacy-policy/87578a2c-df2c-489a-a4b4-d5880793048b",
    cookies: "https://app.termly.io/document/cookie-policy/cff267bf-013b-4adc-9de4-4646e2f4442b",
    terms: "https://app.termly.io/document/terms-of-service/3d45b88b-d1d4-4e12-a51d-be2c0eb4fb7c",
    linkedin: "https://www.linkedin.com/company/illustria-io/",
  };

  return (
    <div className={`flex flex-col items-center lg:justify-center bg-[#090722] w-full lg:h-[294px] px-[24px] md:py-[30px] py-[30px]`}>
      <div className="max-w-[1560px] w-full">
        <div className="flex lg:flex-row flex-col items-center justify-between gap-5">
          <img src={logoWhite} alt="logo" className="md:w-1/5 lg:w-auto" />
          <Divider flexItem sx={{ backgroundColor: "#374151", my: "8px" }} className="md:hidden" />
          <FooterLinks links={links} openCaseStudy={openCaseStudy} />
          <FooterLinksGrid links={links} />
        </div>
        <Divider sx={{ background: "#374151", my: "28px" }} />
        <div className="text-white flex md:flex-row flex-col items-center gap-[17px] justify-between">
          <p className="font-medium text-center"> © {year} Illustria. All rights reserved.</p>
          <div className="flex md:flex-row sm:flex-col items-center gap-1 font-medium">
            <p className="text-[#7E8492]">Designed with</p>
            <img src={heart} alt="heart" />
            <p className="text-[#7E8492]">
              by <span className="text-white">Whitespace</span>
            </p>
          </div>
        </div>
      </div>
      {caseStudyModalOpen && (
        <CaseStudyModal onClose={closeCaseStudy} modalRef={caseStudyModalRef}>
          <CaseStudyPage />
        </CaseStudyModal>
      )}
    </div>
  );
};

export default Footer;

const FooterLinks = ({ links, openCaseStudy }) => {
  return (
    <div className="sm:flex hidden sm:w-1/2 lg:w-full md:w-full md:flex-row justify-between lg:justify-end flex-wrap lg:items-center gap-y-4 lg:gap-x-8 md:gap-x-4  text-[#7E8492]">
      <div className="flex items-center gap-1">
        <a href="#" onClick={openCaseStudy} className="hover:text-white flex items-center gap-1 transition-colors duration-150">
          Case Study
        </a>
        <img src={chip} alt="chip" />
      </div>

      <Divider orientation="vertical" flexItem sx={{ background: "#374151" }} className="hidden md:block " />
      <a href={links.privacy} target="_blank" className="hover:text-white transition-colors duration-300">
        Privacy Policy
      </a>
      <Divider orientation="vertical" flexItem sx={{ background: "#374151" }} className="hidden md:block" />
      <a href={links.linkedin} target="_blank" className="hover:text-white flex items-center gap-1 transition-colors duration-150">
        <LinkedInIcon sx={{ mt: -0.5 }} /> Follow Us
      </a>
      <Divider orientation="vertical" flexItem sx={{ background: "#374151" }} className="hidden md:block" />
      <a
        href={"https://calendly.com/idan-illustria/demo?hide_landing_page_details=1&primary_color=f26800&embed_type=PopupWidget&embed_domain=1&back=0"}
        target="_blank"
        className="hover:text-white flex items-center gap-1 transition-colors duration-150 "
      >
        {" "}
        <AlternateEmailIcon sx={{ mt: -0.5 }} />
        Get In Touch
      </a>
    </div>
  );
};

const FooterLinksGrid = ({ links }) => {
  return (
    <div className="grid sm:hidden grid-cols-1 text-[#7E8492] gap-6">
      <Divider orientation="vertical" flexItem sx={{ background: "#374151" }} className="hidden md:block " />

      <Divider orientation="vertical" flexItem sx={{ background: "#374151" }} className="hidden md:block" />
      <div className="flex items-center gap-1">
        <LinkedInIcon />
        <a href={links.linkedin} target="_blank" className="hover:text-white flex items-center gap-1 transition-colors duration-150">
          Follow Us
        </a>
      </div>
      <Divider orientation="vertical" flexItem sx={{ background: "#374151" }} className="hidden md:block" />
      <a
        href={"https://calendly.com/idan-illustria/demo?hide_landing_page_details=1&primary_color=f26800&embed_type=PopupWidget&embed_domain=1&back=0"}
        target="_blank"
        className="hover:text-white ml-[-.5rem] flex items-center gap-1 transition-colors duration-150"
      >
        {" "}
        <AlternateEmailIcon />
        Get In Touch
      </a>
    </div>
  );
};
