const styles = {
  screens: "max-w-[1440px] xxl:max-w-[1590px] w-full",
  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",
  active: "text-orange font-bold",
  responsiveFlex: "flex md:flex-row flex-col",
  container: "lg:px-[82px] lg:py-[65px] py-[45px] px-[24px] ",
  headline: "font-semibold lg:text-[24px] text-[20px] xxl:mb-[40px] mb-[32px] capitalize",
  headlineLG: "font-bold lg:text-[32px] text-[20px]",
  paragraph: "text-[14px] leading-[160%] text-[#090722]",
  grid: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 ",
  caseStudyButton: "lg:max-w-[171px] bg-[#10B98133] rounded-lg px-2 py-[10px] text-[#090722] text-center font-medium leading-[24px] flex gap-2 items-center justify-center",
  hero: {
    headline: "font-bold xl:text-[62px] text-[38px] leading-[110.5%] mb-5 capitalize",
    paragraph: "md:text-[20px] text-[20px] leading-[135%] mb-[50px]  w-full text-opacity-80",
  },
  caseStudy: {
    container: "lg:px-[84px] lg:py-[80px] py-[45px] px-[34px] max-w-[1440px] xxl:max-w-[1536px]",
    dataBox: "text-center md:text-start lg:py-[88.55px] py-[40px] px-[20px]",
    button: {
      text: "md:text-[16px] text-[10px] font-semibold md:font-medium text-[white] text-[24px] leading-[32px] uppercase",
    },
    bussinessChallenge: {
      header: "uppercase text-orange font-bold tracking-[6.48px] text-md sm:text-lg leading-[25px] sm:leading-[36px]",
      headline: "text-[20px] md:text-[24px] font-bold leading-[32px] text-[#0D1220]",
      paragraph: "text-md sm:text-lg text-[#425466] ",
    },
  },
  responsive: {
    sectionContainer: "w-full max-w-[1654px] pt-6 px-6 lg:pt-0 lg:px-[80px] xxl:px-[32px]",
  },
};

export default styles;
