import {
  isracart,
  sealight,
  sentinelOne,
  amit,
  rafel,
  devIcon,
  lockIcon,
  SCAIcon,
  triIcon,
  liron,
  bitbucket,
  gitlab,
  github,
  teams,
  nexus,
  discord,
  artifactory,
  slack,
  solution_1,
  solutionLg_1,
  solution_2,
  solutionLg_2,
  solution_3,
  solutionLg_3,
  noamRathaus,
  dotan,
  authomize,
  isracardHeb,
} from "../assets";

import { aviram, bogdan, gili, idan, igal } from "../assets/personnel";

export const navLinks = [
  {
    id: "product",
    title: "The Product",
    chip: false,
  },
  {
    id: "problem",
    title: "The Problem",
    chip: false,
  },
  {
    id: "solution",
    title: "The Solution",
    chip: false,
  },
];

export const feedback = [
  {
    id: "1",
    name: "Rafel Ivgi",
    title: "Principal Cyber",
    profileImg: rafel,
    content: "“illustria are pioneers in **leading safe and responsible** use of open source packages & software supply chains.“",
    logo: sentinelOne,
  },
  {
    id: "2",
    name: "Liron Guterman",
    title: "CISO",
    profileImg: liron,
    content: "“illustria uses a unique shift-left approach to security to protect against software supply chain attacks via open source.“",
    logo: isracart,
    altLogo: isracardHeb,
  },
  {
    id: "3",
    name: "Amit Hadari",
    title: "R&D CTO",
    profileImg: amit,
    content: "“With illustria, I was exposed to a more **innovative approach to handling and securing open source** in my company.“",
    logo: sealight,
  },
  {
    id: "4",
    name: "Dotan Bar Noy",
    title: "Authomize's Co-Founder & CEO",
    profileImg: dotan,
    content: "“**illustria is setting a new standard for supply chain attacks.** Increase your company's dev-velocity without compromising cyber security directives.“",
    logo: authomize,
  },
];

export const problemData = [
  {
    id: "1",
    number: "2049",
    content: "Codebases audited in 2021",
  },
  {
    id: "2",
    number: "97",
    content: "Contained open source",
  },
  {
    id: "3",
    number: "87",
    content: "Included security & risk assessments",
  },
];

export const aboutUs = [
  {
    id: "1",
    name: "Idan Wiener",
    profileImg: idan,
    title: "CEO",
    role1: "Israeli Naval \n Academy Officer",
    role2: "Product \n Manager",
    role3: "MBA \n TAU",
    linkedin: "https://www.linkedin.com/in/idan-wiener-aa8ab6130/",
    content_1: "Enter text here",
  },
  {
    id: "2",
    profileImg: bogdan,
    name: "Bogdan Kortnov",
    title: "CTO",
    role1: "Cyber Security \n Architect",
    role2: "Penetration \n Tester",
    role3: "Software \n Engineer",
    linkedin: "https://www.linkedin.com/in/bogdan-kortnov-58996b213/",
    content_1: "Enter text here",
  },
];

export const managment = [
  {
    id: "1",
    profileImg: gili,
    name: "Gili Elkin",
    title: "Director",
    content_1: "Founding General Partner at ICI Fund",
    content_2: "",
    linkedin: "https://www.linkedin.com/in/gilielkin/",
  },
  // {
  //   id: "2",
  //   profileImg: DannyYamin,
  //   name: "Danny Yamin",
  //   title: "Director",
  //   content_1: "Global Vice-President @ Microsoft",
  //   content_2: "GM @ Microsoft ISR",
  //   linkedin: "https://www.linkedin.com/in/danny-yamin-78380082/"
  // },
  {
    id: "3",
    profileImg: aviram,
    name: "Aviram Jenik",
    title: "Advisor",
    content_1: "CEO & Founder \n of Beyond Security",
    content_2: "",
    linkedin: "https://www.linkedin.com/in/aviramjenik",
  },
  {
    id: "4",
    profileImg: igal,
    name: "Yigal Unna",
    title: "Advisor",
    content_1: "Former Director General at \n Israel National Cyber \n Directorate",
    content_2: "",
    linkedin: "https://www.linkedin.com/in/yigal-i-unna-86408342/",
  },
  // {
  //   id: "4",
  //   profileImg: noamRathaus,
  //   name: "Noam Rathaus",
  //   title: "Advisor",
  //   content_1:
  //     "CTO & Founder of Beyond Security",
  //   content_2: "",
  //   linkedin: "https://www.linkedin.com/in/noamrathaus/"
  // },
];

export const aboutUsMobile = [
  {
    id: "1",
    profileImg: idan,
    mobileImg: idan,
    name: "Idan Wiener",
    title: "CEO",
    content_1: "Israeli Naval Academy Officer",
    content_2: "Product Manager",
    content_3: "MBA TAU",
    linkedin: "https://www.linkedin.com/in/idan-wiener-aa8ab6130/",
  },
  {
    id: "2",
    profileImg: bogdan,
    mobileImg: bogdan,
    name: "Bogdan Kortnov",
    title: "CTO",
    content_1: "Cyber Security Architect",
    content_2: "Penetration Tester",
    content_3: "Software Engineer",
    linkedin: "https://www.linkedin.com/in/bogdan-kortnov-58996b213/",
  },
];

export const problems = [
  {
    id: "1",
    icon: lockIcon,
    span: "CISOs/CTOs cant manage risks ",
    text: "they don’t know which open source is deployed on their organization.",
  },
  {
    id: "2",
    icon: triIcon,
    span: "No easy way exists",
    text: "to secure open-source packages before they enter the organization",
  },
  {
    id: "3",
    icon: SCAIcon,
    span: "Secure-Code-Analysis (SCA) solutions ",
    text: "Create false of security. customers are already infected.",
  },
  {
    id: "4",
    icon: devIcon,
    span: "Developers don’t know enough",
    text: "How to manage open-source security.",
  },
];

export const solutions = [
  {
    id: "1",
    solutionImg: solution_1,
    solutionImgLg: solutionLg_1,
    solutionTitle: "Stop Supply Chain Attacks",
    solutionContent:
      "Proactively detect and stop malicious packages, maintainers, and behaviors while using open source packages with our gateway. Protect against attacks such as: Typosquatting, Dependency Confusion, and Starjacking.",
  },
  {
    id: "2",
    solutionImg: solution_2,
    solutionImgLg: solutionLg_2,
    solutionTitle: "End-to-End Watchdog Security",
    solutionContent:
      "Our end-to-end, agentless watchdog for open source packages offers coverage throughout your organization's software development lifecycle, from the download phase on developer workstations to build stages, deployment, and runtime. With our protection, your applications will be secure at every stage of their lifecycle.",
  },
  {
    id: "3",
    solutionImg: solution_3,
    solutionImgLg: solutionLg_3,
    solutionTitle: "Enforce Your Policy",
    solutionContent:
      'By allowing you to create deeply customizable workflows for your environments, projects, groups, users, and network locations, we shift the responsibility for the use of open source packages from developers to dedicated security teams. This "shift left" approach ensures that security is considered from the earliest stages of the development process.',
  },
];

export const integrations = [bitbucket, gitlab, github, teams, nexus, discord, artifactory, slack];
