import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import arrowRight from "../../assets/icons/arrow-right.svg";

import CalendaryPopup from "./CalendaryPopup";

const Calendary = () => {
  const [openCalendly, setOpenCalendly] = useState(false);

  return (
    <>
      <button
        className={`w-full max-w-[393px] lg:w-[171px] h-[44px] px-[20px] py-[10px] h-[42px] text-white rounded-lg text-center font-bold bg-black duration-100 hover:opacity-60 flex gap-2 items-center justify-center`}
        onClick={() => setOpenCalendly(true)}
      >
        Get a Demo
        <img src={arrowRight} alt="arrow" width={"24px"} height={"24px"} />
      </button>
      {openCalendly && (
        <>
          <CalendaryPopup />
          <button
            className="fixed top-0 right-0 m-10"
            onClick={() => setOpenCalendly(false)}
            style={{
              zIndex: 205,
              width: "45px",
              height: "45px",
              borderRadius: "50%",
            }}
          >
            <CloseIcon htmlColor="white" />
          </button>
        </>
      )}
    </>
  );
};

export default Calendary;
