import React, { useState, useEffect, useRef } from "react";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import CloseIcon from "@mui/icons-material/Close";

import video from "../../../assets/video/video.mp4";
import videoPoster from "../../../assets/video/illustria-video-poster.svg";
import newIcon from "../../../assets/icons/new.svg";
import videoPosterMobile from "../../../assets/video/videoMobile.svg";

import Calendary from "../../utils/Calendary";
import CaseStudyModal from "../../utils/CaseStudyModal";
import CaseStudyPage from "../../../pages/CaseStudyPage";
import heroSectionStyles from "./styles";

const HeroSection = ({ setActive, isBannerRecentlyClosed }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [caseStudyModalOpen, setCaseStudyModalOpen] = useState(false);
  const caseStudyModalRef = useRef(null);
  const videoRef = useRef(null);
  const modalRef = useRef(null);

  const handlePlayPause = () => {
    setIsModalOpen(true);
  };

  const handleVideoClick = () => {
    const videoElement = videoRef.current;
    if (videoElement.paused) {
      videoElement.play();
      setIsPlaying(true);
    } else {
      videoElement.pause();
      setIsPlaying(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsPlaying(false);
  };

  const handleClickOutsideModal = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideModal);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, []);

  const openCaseStudy = () => {
    setCaseStudyModalOpen(true);
  };

  const closeCaseStudy = () => {
    setCaseStudyModalOpen(false);
  };

  // Modal component
  const Modal = ({ onClose, children }) => {
    const [isModalHovered, setIsModalHovered] = useState(false);
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div
          ref={modalRef}
          className="p-4 rounded-lg relative"
          style={{
            width: "90%",
            maxWidth: "1100px",
            maxHeight: "90vh",
            overflow: "auto",
          }}
          onMouseEnter={() => setIsModalHovered(true)}
          onMouseLeave={() => setIsModalHovered(false)}
        >
          {isModalHovered && (
            <button className="absolute top-5 right-6 p-2" onClick={onClose}>
              <CloseIcon
                style={{
                  cursor: "pointer",
                  right: ".2rem",
                  background: "none",
                  zIndex: 999999999,
                  position: "absolute",
                }}
              />
            </button>
          )}
          {children}
        </div>
      </div>
    );
  };
  //sm:h-[56px] h-[80px]
  return (
    <div data-section id="overview" className="bg-gray_bg flex justify-center">
      <div className={`${heroSectionStyles.container} ${isBannerRecentlyClosed ? "mt-[70px]" : "sm:mt-[126px] mt-[150px]"}`}>
        {/* TITLE */}
        <div className="flex flex-col">
          <p className={`${heroSectionStyles.heroTitle}`}>
            {`Find the unknown threats`}
            <span className="text-orange">.</span>
          </p>
          <p className={`${heroSectionStyles.heroText} py-5`}>
            {`Protect yourself from `}
            <span id="day-zero" className="text-orange font-medium">
              {`0day`}
            </span>
            {` attacks initiated in open source packages.`}
          </p>

          <div className={`flex flex-col lg:flex-row  gap-[16px] items-center lg:w-[140%] py-5`}>
            <Calendary />
            <button onClick={openCaseStudy} className={`${heroSectionStyles.caseStudyButton} ${heroSectionStyles.buttonSize}`}>
              Case Study
              <img src={newIcon} alt="new" className="" />
            </button>
          </div>
        </div>

        {/* VIDEO*/}
        <div className="relative">
          <video className={`${heroSectionStyles.videoContainer}`} poster={videoPoster} controls={false} ref={videoRef} onClick={handleVideoClick}>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <video className={`${heroSectionStyles.videoContainerMobile}`} poster={videoPosterMobile} controls={false} ref={videoRef} onClick={handleVideoClick}>
            <source src={video} type="video/mp4" />
          </video>

          <div className="absolute inset-0 flex items-center justify-center transition-opacity">
            <div onClick={handlePlayPause} className="w-16 h-16 hover:bg-[#f29146] lg:w-20 lg:h-20 bg-[#f18534] border-2 border-white rounded-full flex items-center justify-center cursor-pointer">
              {isPlaying ? (
                <PauseIcon style={{ fontSize: 48, color: "white", cursor: "pointer" }} onClick={handlePlayPause} />
              ) : (
                <PlayArrowIcon style={{ fontSize: 48, color: "white", cursor: "pointer" }} onClick={handlePlayPause} />
              )}
            </div>
          </div>
        </div>
      </div>

      {caseStudyModalOpen && (
        <CaseStudyModal onClose={closeCaseStudy} modalRef={caseStudyModalRef}>
          <CaseStudyPage />
        </CaseStudyModal>
      )}

      {isModalOpen && (
        <Modal onClose={closeModal}>
          <video className="w-full h-auto rounded-xl" controls={true} src={video} type="video/mp4" autoPlay />
        </Modal>
      )}
    </div>
  );
};

export default HeroSection;
