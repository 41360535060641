import React from "react";

import Thumbnnail1 from "../../../assets/indistryInitative/thumbnail-1.png";
import Thumbnnail2 from "../../../assets/indistryInitative/thumbnail-2.png";
import Thumbnnail3 from "../../../assets/indistryInitative/thumbnail-3.png";

import styles from "./styles";
import commonStyles from "../../../styles";

const IndustryInitiative = () => {
  return (
    <div id="industry-initiative" className={`flex flex-col items-start lg:py-[100px] pb-6 md:pb-0`}>
      <h1 className={`${commonStyles.headline}`}>Industry Initiatives</h1>

      <div className={`${styles.container}`}>
        <div className={`${styles.card}`}>
          <img src={Thumbnnail1} alt="thumbnail1" className={`${styles.image}`} style={styles.imageStyles} />
          <div className={`${styles.textContainer}`}>
            <p className={`${styles.text}`}>{`Safeguarding artifact integrity across softwares supply chain`}</p>
            <a href="https://slsa.dev/" target="_blank" rel="noreferrer" className="w-fit">
              <button className={`${styles.button}`}>Google SLSA</button>
            </a>
          </div>
        </div>
        <div className={`${styles.card}`}>
          <img src={Thumbnnail2} alt="thumbnail2" className={`${styles.image}`} style={styles.imageStyles} />
          <div className={`${styles.textContainer}`}>
            <p className={`${styles.text}`}>{`Executive Order on Improving the Nation’s Cybersecurity `}</p>
            <a
              href="https://www.whitehouse.gov/briefing-room/presidential-actions/2021/05/12/executive-order-on-improving-the-nations-cybersecurity/"
              target="_blank"
              rel="noreferrer"
              className="w-fit"
            >
              <button className={`${styles.button}`}>Executive Order 14028</button>
            </a>
          </div>
        </div>
        <div className={`${styles.card}`}>
          <img src={Thumbnnail3} alt="thumbnail3" className={`${styles.image}`} style={styles.imageStyles} />
          <div className={`${styles.textContainer}`}>
            <p className={`${styles.text}`}>{`A08:2021 – Software and Data Integrity Failures`}</p>
            <a href="https://owasp.org/Top10/" target="_blank" rel="noreferrer" className="w-fit">
              <button className={`${styles.button}`}>OWASP Top 10</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryInitiative;
